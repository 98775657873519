export const projectTypes = [
  {
    text: 'Time&Material',
    value: 'Time&Material',
    isDefault: true,
  },
  {
    text: 'Fix-Budget',
    value: 'Fix-Budget',
  },
];
