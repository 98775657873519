<template>
  <div>
    <v-row class="actions-bar pa-4 bar-shadow primary-background">
      <v-col>
        <ProjectPageFilters
          :office-filter="projectOfficeFilter"
          :type-filter="projectTypeFilter"
          :project-name-filter="projectNameFilter"
          @changeFilter="changeFilter"
        />
      </v-col>
      <v-col
        v-if="isUserAdmin"
        class="actions"
      >
        <Button
          id="add-project-button"
          color="primary"
          @clickAction="isDialogOpened = true"
        >
          {{ addProjectText }}
        </Button>
      </v-col>
    </v-row>
    <v-row class="pa-4">
      <v-card>
        <ProjectTable
          :projects="projectsFiltered"
          @save="getProjects"
        />
      </v-card>
    </v-row>

    <AddProjectDialog
      :is-opened="isDialogOpened"
      @close="isDialogOpened = false"
      @save="getProjects"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Button } from '@/components/shared';

import AddProjectDialog from './AddProjectDialog.vue';
import ProjectPageFilters from './ProjectPageFilters.vue';
import ProjectTable from './ProjectTable.vue';

export default {
  components: {
    AddProjectDialog,
    Button,
    ProjectPageFilters,
    ProjectTable,
  },
  data() {
    return {
      isFiltersOpened: true,
      isDialogOpened: false,
      addProjectText: 'Add Project',
    };
  },
  computed: {
    ...mapGetters('projects/filters', [
      'projectNameFilter',
      'projectOfficeFilter',
      'projectTypeFilter',
      'projectsFiltered',
    ]),
    ...mapGetters('auth/account', [
      'user',
      'isUserAdmin',
    ]),
  },
  created() {
    this.getProjects();
  },
  methods: {
    ...mapActions('projects/filters', [
      'setNameFilter',
      'setProjectOfficeFilter',
    ]),
    ...mapActions('projects/filters', ['getProjectList']),

    changeFilter(value, filter) {
      if (filter === 'office-filter') {
        this.setProjectOfficeFilter(value);
      } else {
        this.setNameFilter(value);
      }
    },

    getProjects() {
      this.getProjectList();
    },
  },
};
</script>

<style lang="less">
@import "~@/styles/actions-bar";
</style>
