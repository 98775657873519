<template>
  <v-row class="filters-row-wrapper">
    <v-col>
      <v-text-field
        label="Project"
        class="secondary-background"
        prepend-inner-icon="search"
        outlined
        dense
        single-line
        hide-details
        :value="projectNameFilter"
        @input="setNameFilter"
      />
    </v-col>
    <v-col>
      <MultiChoiceFilter
        label="Office"
        class="secondary-background"
        item-text="name"
        outlined
        dense
        hide-details
        :items="offices"
        :value="projectOfficeFilter"
        @update="setOfficesFilter"
      />
    </v-col>
    <v-col>
      <MultiChoiceFilter
        label="Type"
        class="secondary-background"
        item-text="title"
        outlined
        dense
        hide-details
        :items="projectTypeFilterOptions"
        :value="projectTypeFilter"
        @update="setTypesFilter"
      />
    </v-col>
    <v-col>
      <MultiChoiceFilter
        label="Status"
        class="secondary-background"
        item-text="title"
        outlined
        dense
        hide-details
        :items="projectStatusFilterOptions"
        :value="projectStatusFilter"
        @update="setStatusesFilter"
      />
    </v-col>
    <v-col class="analitic-btn">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <a href="/dashboard?targetName='PM'">
            <v-btn
              class="mr-4"
              icon
              v-on="{ ...menu, ...tooltip }"
            >
              <AnalyticIcon>Some text</AnalyticIcon>
            </v-btn>
          </a>
        </template>
        <span>{{ BItext }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

import AnalyticIcon from '@/assets/BI1.svg';
import { Button, UnitShortcuts, MultiChoiceFilter } from '@/components/shared';

export default {
  components: {
    MultiChoiceFilter,
    UnitShortcuts,
    AnalyticIcon,
  },
  data() {
    return {
      BItext: 'Project Analytics',
    };
  },
  computed: {
    ...mapGetters('shared/offices', ['offices']),
    ...mapGetters('projects/filters', [
      'projectNameFilter',
      'projectOfficeFilter',
      'projectTypeFilter',
      'projectStatusFilter',
      'projectTypeFilterOptions',
      'projectStatusFilterOptions',
    ]),
  },
  created() {
    this.getOffices();
  },
  methods: {
    ...mapActions('projects/filters', [
      'setOfficesFilter',
      'setNameFilter',
      'setTypesFilter',
      'setStatusesFilter',
    ]),
    ...mapCacheActions('shared/offices', ['getOffices']),
  },
};
</script>

<style lang="less">
  @import "~@/styles/actions-bar";
  @import "~@/styles/analytic-btn";
</style>
