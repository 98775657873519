<template>
  <Dialog
    is-persistent
    :title="dialogTitle"
    :dialog="isOpened"
    :confirmation-button-text="confirmationButtonText"
    @confirmationAction="addProject"
  >
    <template #content>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row class="pb-4">
          <v-col>
            <v-text-field
              id="project-title-input"
              v-model="title"
              :rules="titleRules"
              data-cy="project-title-input"
              class="secondary-background"
              label="Title"
              hide-details
              required
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pr-2">
            <DatePicker
              ref="startDate"
              v-model="startDate"
              label="Start date"
            />
          </v-col>
          <v-col>
            <DatePicker
              ref="endDate"
              v-model="endDate"
              label="End date"
              :min-date="startDate"
            />
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col>
            <v-select
              v-model="type"
              :menu-props="{ offsetY: true }"
              :items="projectTypes"
              class="secondary-background"
              label="Type"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col>
            <v-select
              v-model="status"
              :menu-props="{ offsetY: true }"
              :items="projectStatusFilterValues"
              class="secondary-background"
              label="Status"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col>
            <MultiChoiceFilter
              label="Office"
              class="secondary-background"
              item-text="name"
              required
              outlined
              dense
              hide-details
              :is-error="isOfficeError"
              :items="offices"
              @update="officesSelected"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </Dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import { Dialog, DatePicker, MultiChoiceFilter } from '@/components/shared';
import { projectStatusFilterValues } from '@/constants/projectStatuses';
import { projectTypes } from '@/constants/projectTypes';
import { DateHelper } from '@/helpers/date.helper';
import { offices } from '@/store/shared/offices-module';

const getInitialStartDate = () => DateHelper.toIso(moment());
const getInitialEndDate = () => DateHelper.toIso(moment()
  .add(1, 'years'));

const defaultType = projectTypes.find((type) => type.isDefault) || projectTypes[0];
const defaultStatus = projectStatusFilterValues.find((status) => status.isDefault || projectStatusFilterValues[0]);

const getInitialState = () => ({
  date: {
    startDate: getInitialStartDate(),
    endDate: getInitialEndDate(),
  },
  title: '',
  type: defaultType.value,
  status: defaultStatus.value,
  officeIds: [],
});

export default {
  components: {
    Dialog,
    DatePicker,
    MultiChoiceFilter,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogTitle: 'Add Project',
      confirmationButtonText: 'Add',
      valid: true,
      projectItem: getInitialState(),
      title: '',
      startDate: getInitialStartDate(),
      endDate: getInitialEndDate(),
      type: defaultType.value,
      status: defaultStatus.value,
      titleRules: [(value) => !!value || 'Title is required'],
      isOfficeError: false,
      projectStatusFilterValues,
      projectTypes,
    };
  },
  computed: {
    offices() {
      return offices;
    },
    ...mapGetters('projects/main', ['projectId']),
    ...mapGetters('shared/offices', ['offices']),

    form() {
      return { ...this.projectItem };
    },
  },
  watch: {
    startDate(value) {
      if (!DateHelper.isDayBefore(value, this.endDate)) {
        const newEndDate = DateHelper.toIso(moment(value)
          .endOf('month'));

        this.endDateChanged(newEndDate);
        this.endDate = newEndDate;
      }

      this.startDateChanged(value);
      this.startDate = value;
    },
    endDate(value) {
      this.endDateChanged(value);
    },
  },
  async created() {
    document.addEventListener('keydown', this.handleKeypress);
    document.addEventListener('keyup', this.handleKeyup);

    this.getOffices();
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeypress);
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    ...mapActions('shared/offices', ['getOffices']),
    ...mapActions('projects/main', ['createProject']),

    startDateChanged(value) {
      this.projectItem.date.startDate = value;
    },
    endDateChanged(value) {
      this.projectItem.date.endDate = value;
    },

    async addProject(confirmed) {
      if (!confirmed) {
        this.closeDialog();

        return;
      }

      if (!this.validate()) {
        return;
      }

      const project = {
        title: this.title,
        type: this.type,
        status: this.status,
        start_date: this.projectItem.date.startDate,
        end_date: this.projectItem.date.endDate,
        office_ids: this.projectItem.officeIds,
      };

      try {
        await this.createProject(project);
        this.$emit('save');
      } finally {
        if (this.projectId) {
          this.closeDialog();
          this.navigateToDetails(this.projectId);
        }
      }
    },
    closeDialog() {
      this.$emit('close');
      this.reset();
    },
    reset() {
      Object.assign(this.projectItem, getInitialState());
      this.$refs.startDate.date = getInitialStartDate();
      this.$refs.endDate.date = getInitialEndDate();
      this.resetValidation();
    },
    validate() {
      if (!this.projectItem.officeIds.length) {
        this.isOfficeError = true;
      }

      return this.$refs.form.validate() && !this.isOfficeError;
    },
    handleKeypress(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.closeDialog();
      }
    },
    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault();
        this.addProject();
      }
    },
    officesSelected(value) {
      this.projectItem.officeIds = value;
      this.isOfficeError = !value.length;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
      this.isOfficeError = false;
    },
    navigateToDetails(projectId) {
      this.$router.push({
        name: 'Project Details',
        params: { id: projectId },
      });
    },
  },
};
</script>
