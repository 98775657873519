<template>
  <tr
    v-if="!isEditing && item.id"
    :key="item.id"
    active-class="highlighted"
    :active="selected"
  >
    <td
      v-for="(header, index) in headers.filter((h) => !h.hidden)"
      :key="index"
    >
      <template v-if="header.value === 'start_date'">
        {{ item.start_date | dateToDisplay(dateFormatSettings) }}
      </template>
      <template v-else-if="header.value === 'end_date'">
        {{ item.end_date | dateToDisplay(dateFormatSettings) }}
      </template>
      <template v-else-if="header.value === 'title'">
        <a @click="navigateToDetails(item)">{{ item.title }}</a>
      </template>
      <template v-else>
        {{ item[header.value] }}
      </template>
    </td>
    <td
      v-if="isUserAdmin"
      class="action-column"
    >
      <template v-if="!isEditing">
        <v-row>
          <v-btn
            text
            icon
            small
            @click.stop="editProject()"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            text
            icon
            small
            @click.stop="deleteProject(item)"
          >
            <v-icon>delete_outline</v-icon>
          </v-btn>
        </v-row>
      </template>
    </td>
  </tr>
  <ProjectRowInput
    v-else
    :key="item.id"
    :project-item="item"
    :headers="headers"
    @save="saveProject"
    @cancel="cancelEditing"
  />
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import ProjectRowInput from '@/components/projects/ProjectRowInput.vue';
import { DateHelper } from '@/helpers/date.helper';

export default Vue.extend({
  components: { ProjectRowInput },
  filters: {
    dateToDisplay: DateHelper.formatDateAccordingSettings,
  },
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user/settings', ['denseTables', 'dateFormatSettings']),
    ...mapGetters('auth/account', ['isUserAdmin']),
    ...mapGetters('shared/offices', ['offices']),
  },
  data() {
    return {
      isEditing: false,
    };
  },
  mounted() {
    const lastEditableTimeUnit = this.getLastEditableTimeUnit;

    if (lastEditableTimeUnit && this.item.id === lastEditableTimeUnit.id) {
      document.addEventListener('keyup', this.arrowUpKeyHandler);
    }
  },
  methods: {
    deleteProject() {
      this.$emit('onDelete', this.item);
    },
    selectItem() {
      this.$emit('onSelect', this.item);
    },
    cancelEditing() {
      this.setEditing(false);
    },
    editProject() {
      this.setEditing(true);
      this.selectItem();
    },
    setEditing(value) {
      this.isEditing = value;
    },
    saveProject(project) {
      this.$emit('onSave', project);
      this.cancelEditing();
    },
    navigateToDetails(item) {
      this.$router.push({
        name: 'Project Details',
        params: {
          id: item.id,
          project: item,
        },
      });
    },
  },
});
</script>

<style lang="less">
@import "~variables";
#timesheet-table {
  .add-button-wrapper {
    position: relative;
    width: 24px;
    height: 100%;

    .v-input {
      padding-left: 24px;
    }

    .add-button {
      min-width: 24px;
      width: 24px;
      height: 24px;
      bottom: -16px;
      z-index: 1;
      overflow: hidden;
      transition: background-color 0.5s ease;
      i {
        transition: transform 0.5s ease, color 0.5s ease;
      }
      &:hover {
        background-color: #f26a399f;
        i {
          color: white;
          transform: rotate(360deg);
        }
      }
    }
  }

  tr:not(:hover) .add-button {
    display: none;
  }

  .v-data-table__checkbox {
    padding-left: 8px;
  }

  .action-column {
    .row {
      margin: 0;

      .v-btn {
        color: #555;
        margin: 1px;
        height: inherit;
        width: inherit;
        min-width: unset;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
          transition: all 0.5s ease;
        }
      }
    }
  }

  .even-day-row-highlight {
    background-color: @grey; // blue-grey lighten-4

    &:hover {
      background-color: @grey-light; // blue-grey lighten-5
    }
  }

  tr[active]:not(.row-editor) {
    background: @orange-12pct;
    &:hover {
      background-color: @grey-light;
    }
  }

  .cell-content-with-ellipsis {
    display: inline-block;
    width: 100%;
    position: relative;

    &:before {
      content: "&nbsp;";
      visibility: hidden;
    }

    span {
      position: absolute;
      left: 0;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // TODO: remove this unused class
  .cell-content {
    display: inline-block;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }

  // Description
  tr td:nth-child(7) {
    word-break: break-all;
  }
}
</style>
