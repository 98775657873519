<template>
  <tr
    id="currentlyEditingProject"
    active
    class="row-editor"
  >
    <td
      v-for="(header, index) in headers.filter((h) => !h.hidden)"
      :key="index"
    >
      <template v-if="header.value === 'start_date'">
        <DatePicker
          ref="startDate"
          v-model="localData.start_date"
          :outlined="false"
        />
      </template>
      <template v-else-if="header.value === 'end_date'">
        <DatePicker
          ref="endDate"
          v-model="localData.end_date"
          :outlined="false"
        />
      </template>
      <template v-else-if="header.value === 'status'">
        <v-select
          v-model="localData.status"
          :menu-props="{ offsetY: true }"
          :items="projectStatusFilterValues"
          class="secondary-background"
          label="Status"
          hide-details
          dense
        />
      </template>
      <template v-else-if="header.value === 'office_name'">
        <MultiChoiceFilter
          label="Office"
          class="secondary-background"
          item-text="name"
          required
          dense
          hide-details
          :items="offices"
          :value="localData.office_ids"
          @update="officesSelected"
        />
      </template>
      <template v-else-if="header.value === 'type'">
        <v-select
          v-model="localData.type"
          :menu-props="{ offsetY: true }"
          :items="projectTypes"
          class="secondary-background"
          label="Project type"
          hide-details
          dense
        />
      </template>
      <template v-else-if="header.value === 'title'">
        <a @click="navigateToDetails(localData)">{{ localData.title }}</a>
      </template>
      <template v-else>
        {{ localData[header.value] }}
      </template>
    </td>
    <td class="action-column">
      <v-row>
        <v-btn
          text
          icon
          small
          @click.stop="updateProject(localData)"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          small
          @click.stop="cancelEdit()"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </v-row>
    </td>
  </tr>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { TimeTooltip } from '@/components/shared';
import DatePicker from '@/components/shared/DatePicker.vue';
import MultiChoiceFilter from '@/components/shared/MultiChoiceFilter.vue';
import { projectStatusFilterValues } from '@/constants/projectStatuses';
import { projectTypes } from '@/constants/projectTypes';

export default {
  name: 'ProjectRowInput',
  components: {
    DatePicker,
    MultiChoiceFilter,
    TimeTooltip,
  },
  props: {
    projectItem: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      endDateMenu: false,
      startDateMenu: false,
      localData: _.cloneDeep(this.projectItem),
      projectStatusFilterValues,
      projectTypes,
    };
  },
  computed: {
    ...mapGetters('shared/offices', ['offices']),
  },
  mounted() {},
  methods: {
    cancelEdit() {
      this.$emit('cancel');
    },
    officesSelected(value) {
      this.localData.office_ids = value;
    },
    updateProject() {
      this.$emit('save', this.localData);
    },
    navigateToDetails(item) {
      this.$router.push({
        name: 'Project Details',
        params: {
          id: item.id,
          project: item,
        },
      });
    },
  },
};
</script>

<style lang="less">
@import "~variables";

.row-editor {
  .project-input {
    max-width: 270px;
  }

  .v-label {
    font-size: @table-font-size;
  }

  .v-text-field__slot {
    font-size: @table-font-size;
  }

  .v-text-field {
    input {
      font-size: @table-font-size;
    }
  }

  .clipped div.v-select__selection {
    overflow: hidden;
    white-space: nowrap;
  }

  // Validation error message
  .v-input {
    .v-text-field__details {
      overflow: visible;
    }

    .v-messages__message {
      position: absolute;
      background: @soft-peach;
      border: 1px solid @pink;
      border-radius: 0 2px 2px 2px;
      width: max-content;
      max-width: 120px;
      padding: 3px;
      color: @red-dark;
      box-shadow: 0 -1px 8px 2px @pink-light;

      &::before {
        content: "";
        position: absolute;
        border: solid transparent;
        border-width: 0 6px 5px 0;
        border-bottom-color: @pink;
        top: -5px;
        left: -1px;
      }
    }
  }
  .time__cell {
    position: relative;
  }
}
</style>
