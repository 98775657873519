<template>
  <div>
    <v-data-table
      class="projects-data-table"
      :headers="headers"
      :items="projects"
      disable-pagination
      no-data-text="No data available"
      hide-default-footer
      fixed-header
      :dense="denseTables"
      :height="defaultTableHeight"
      :sort-by="['title']"
    >
      <template #item="{ isSelected, select, item, index }">
        <ProjectTableRow
          :key="index"
          :selected="isSelected"
          :select="select"
          :item="item"
          :loading="loading"
          :headers="headers"
          @onDelete="askProjectDeleteConfirmation"
          @onSave="saveProject"
          @onSelect="selectItem"
        />
      </template>

      <template #footer>
        <v-divider />
        <v-row class="table-footer">
          <div class="footer-text">Total records: {{ projects.length }}</div>
        </v-row>
      </template>
    </v-data-table>

    <DeletionConfirmationDialog
      :dialog="confirmationDialog"
      title="Confirmation"
      text="Are you sure you want to delete this project?"
      confirmation-button-text="Delete"
      @confirmationAction="projectDeleteConfirmation"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ProjectTableRow from '@/components/projects/ProjectTableRow.vue';
import { Dialog as DeletionConfirmationDialog } from '@/components/shared';
import MultiChoiceFilter from '@/components/shared/MultiChoiceFilter.vue';
import { projectStatusFilterValues } from '@/constants/projectStatuses';
import { projectTypes } from '@/constants/projectTypes';
import { defaultTableHeight } from '@/constants/tableHeights';
import { DateHelper } from '@/helpers/date.helper';

const defaultStatus = projectStatusFilterValues.find(
  (status) => status.isDefault || projectStatusFilterValues[0]
);

export default {
  components: {
    ProjectTableRow,
    MultiChoiceFilter,
    DeletionConfirmationDialog,
  },
  filters: {
    dateToDisplay: DateHelper.formatDateAccordingSettings,
  },
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultTableHeight,
      headers: [
        {
          text: 'Project',
          value: 'title',
        },
        {
          text: 'Office',
          value: 'office_name',
          width: '200px',
        },
        {
          text: 'Type',
          value: 'type',
          width: '200px',
        },
        {
          text: 'Start Date',
          value: 'start_date',
          width: '150px',
        },
        {
          text: 'End Date',
          value: 'end_date',
          width: '150px',
        },
        {
          text: 'Status',
          value: 'status',
          width: '250px',
        },
        {
          hidden: true,
          width: '90px',
          sortable: false,
        },
      ],
      confirmationDialog: false,
      itemToDelete: '',
      startDateMenu: false,
      endDateMenu: false,
      loading: false,
      selected: [],
      projectStatusFilterValues,
      projectTypes,
    };
  },
  computed: {
    ...mapGetters('user/settings', ['denseTables', 'dateFormatSettings']),
    ...mapGetters('auth/account', ['isUserAdmin']),
    ...mapGetters('shared/offices', ['offices']),
  },
  methods: {
    ...mapActions('projects/main', ['deleteProject', 'updateProject']),
    ...mapActions('shared/offices', ['getOffices']),

    askProjectDeleteConfirmation(item) {
      this.itemToDelete = item;
      this.confirmationDialog = true;
    },
    async projectDeleteConfirmation(confirmed) {
      if (confirmed) {
        await this.deleteProject(this.itemToDelete.id);
        this.$emit('save');
      }

      this.confirmationDialog = false;
    },
    async saveProject(project) {
      await this.updateProject(project);
      this.$emit('save');
    },
    selectItem(item) {
      const foundIndex = this.selected.findIndex((v) => v.id === item.id);

      if (foundIndex !== -1) {
        this.selected.splice(foundIndex, 1);
      } else {
        this.selected.push(item);
      }
    },
  },
  created() {
    this.getOffices();
  },
};
</script>

<style lang="less">
@import "~variables";

.projects-data-table {
  font-size: @table-font-size;

  tr {
    cursor: pointer;
  }

  .action-column {
    .row {
      margin: 0;

      .v-btn {
        color: #555;
        margin: 1px;
        height: inherit;
        width: inherit;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
          transition: all 0.5s ease;
        }
      }
    }
  }
}
</style>
